body{
    margin: 0;
    padding: 0;
}
#root {
    height: 100vh;
}

.appointment-card-email {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
}

@media(min-width: 1440px){
        button.gmail-login-btn div{
            margin-right: 4px !important;
        }
        .makeStyles-loginWithFb-4 .MuiSvgIcon-root{
            margin-right: 0px !important;
            margin-left: 4px !important;
        }
}
@media screen and (max-width: 1440px) {
    body{
        .MuiChip-root{
            height: 25px;
        }
         .MuiCardContent-root {
            padding: 16px !important;
        }
        .MuiTypography-h5,
         span.MuiTypography-root,
         h2.MuiTypography-root {
            font-size: 15px;
        }
         h3.MuiTypography-root {
            font-size: 2rem;
        }
        .MuiTypography-h5{

        }
    } 
    .MuiCardHeader-root {
        padding: 10px;
    }
    .MuiListItem-root {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    body .age-av-card{
        .MuiCardContent-root {
            padding: 10px !important;
            font-size: 15px;
        } 
    }  
    .reg-date p {
        font-size: 12px;
    } 

    .appointment-card-row{
        .MuiCardHeader-title{

        }
    }
  }

  .position-relative{
      position: relative;
  }
  .appointment-grid-item{

  }
  .card-staff-body{
    position: absolute;
    z-index: 99;
    right: 15px;
    top: 16px;
  }

  .mt-1{
      margin-top: 10px;
  }